import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Task, TaskAttachment, TaskPriorityEnum, TaskStatusEnum, User } from '@webcoffee/interfaces';
import { EMPTY, Observable, catchError, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/ui/confirmation-dialog/confirmation-dialog.component';
import { saveAs } from 'file-saver';

export interface TaskState {
    selectedTask: Task | null;
    tasks: Task[];
    addMode: boolean;
    addComment: boolean;
    users: User[];
    activeTab: 'Comments' | 'Attachments';
}

@Injectable()
export class TaskStore extends ComponentStore<TaskState> {
    taskPriorityValue = {
        [TaskPriorityEnum.URGENT]: 0,
        [TaskPriorityEnum.HIGH]: 1,
        [TaskPriorityEnum.MEDIUM]: 2,
        [TaskPriorityEnum.LOW]: 3,
    };
    constructor(private http: HttpClient, private toastr: ToasterService, private dialog: MatDialog) {
        super({
            selectedTask: null,
            tasks: [],
            addMode: false,
            addComment: false,
            users: [],
            activeTab: 'Comments',
        });
    }

    readonly getAddMode = this.select((state) => state.addMode);
    readonly getActiveTasks = this.select((state) =>
        state.tasks
            .filter((t) => t.status !== TaskStatusEnum.CLOSED)
            .sort((a, b) => {
                let prioA = this.taskPriorityValue[a.priority];
                const prioB = this.taskPriorityValue[b.priority];

                if (a.status === TaskStatusEnum.DEV) {
                    prioA--;
                }

                return prioA - prioB;
            }),
    );
    readonly getClosedTasks = this.select((state) =>
        state.tasks
            .filter((t) => t.status === TaskStatusEnum.CLOSED)
            .sort((a, b) => {
                let prioA = this.taskPriorityValue[a.priority];
                const prioB = this.taskPriorityValue[b.priority];

                if (a.status === TaskStatusEnum.DEV) {
                    prioA--;
                }

                return prioA - prioB;
            }),
    );
    readonly getUsers = this.select((state) => state.users);
    readonly getActiveTab = this.select((state) => state.activeTab);
    readonly getSelectedTask = this.select((state) => state.selectedTask);
    readonly getAddComment = this.select((state) => state.addComment);

    readonly addTask = this.updater((state, task: Task) => {
        const tasks = [...state.tasks];
        const taskIndex = state.tasks.findIndex((t) => t.id === task.id);

        if (taskIndex !== -1) {
            tasks.splice(taskIndex, 1, task);
        } else {
            tasks.push(task);
        }

        return { ...state, tasks, selectedTask: state.selectedTask?.id === task.id ? task : state.selectedTask };
    });
    readonly removeTask = this.updater((state, id: string) => {
        return { ...state, tasks: state.tasks.filter((t) => t.id !== id) };
    });

    readonly getTasksEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<Task[]>('/api/tasks').pipe(
                    tap((tasks) => this.patchState({ tasks })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getTaskEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.get<Task>(`/api/tasks/${id}`).pipe(
                    tap((task) => {
                        this.addTask(task);
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getUsersEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<User[]>('/api/users').pipe(
                    tap((users) => this.patchState({ users })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly saveTaskEffect = this.effect((task$: Observable<any>) =>
        task$.pipe(
            switchMap((task) => {
                const selectedTask = this.get().selectedTask;

                if (selectedTask) {
                    return this.http.patch<Task>(`/api/tasks/${selectedTask.id}`, { ...task }).pipe(
                        tap((task) => {
                            this.getTaskEffect(task.id);
                            this.patchState({ addMode: false });
                            this.toastr.success('Task-ul a fost modificat cu success');
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                } else {
                    return this.http.post<Task>('/api/tasks', { ...task, status: TaskStatusEnum.NEW }).pipe(
                        tap((task) => {
                            this.addTask(task);
                            this.patchState({ addMode: false });
                            this.toastr.success('Task-ul a fost adaugat cu success');
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }
            }),
        ),
    );

    readonly saveCommentEffect = this.effect((text$: Observable<any>) =>
        text$.pipe(
            switchMap((text) => {
                const selectedTask = this.get().selectedTask;

                if (selectedTask) {
                    return this.http.post<Task>(`/api/tasks/${selectedTask.id}/comment`, { text }).pipe(
                        tap((comment) => {
                            this.getTaskEffect(selectedTask.id);
                            this.patchState({ addComment: false });
                            this.toastr.success('Comment-ul a fost adaugat cu success');
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }
                return EMPTY;
            }),
        ),
    );

    readonly addAttachmentEffect = this.effect((file$: Observable<Blob>) =>
        file$.pipe(
            switchMap((file: Blob) => {
                const selectedTask = this.get().selectedTask;
                const form = new FormData();

                form.append('file', file);

                if (selectedTask) {
                    return this.http.post<Task>(`/api/tasks/${selectedTask.id}/attachment`, form).pipe(
                        tap((attachment) => {
                            this.getTaskEffect(selectedTask.id);
                            this.toastr.success('Atasamentul a fost adaugat cu success');
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }
                return EMPTY;
            }),
        ),
    );

    readonly downloadAttachmentEffect = this.effect((attachment$: Observable<TaskAttachment>) =>
        attachment$.pipe(
            switchMap((attachment) => {
                const selectedTask = this.get().selectedTask;

                if (selectedTask) {
                    return this.http.get(`/api/tasks/attachment/${attachment.id}`, { headers: { Accept: 'application/octet-stream' }, responseType: 'blob' }).pipe(
                        tap((file: any) => {
                            saveAs(file, attachment.attachment.split('/').pop());
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }
                return EMPTY;
            }),
        ),
    );

    readonly deleteTaskEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.dialog
                    .open(ConfirmationDialogComponent, {
                        data: {
                            message: 'Esti sigur ca vrei sa stergi acest task?',
                        },
                    })
                    .afterClosed()
                    .pipe(
                        switchMap((res) => {
                            if (res.confirmed) {
                                return this.http.delete(`/api/tasks/${id}`).pipe(
                                    tap((_) => {
                                        this.toastr.success('Task-ul a fost sters cu success!');
                                        this.removeTask(id);
                                    }),
                                    catchError((err) => {
                                        this.toastr.error(err.error.message);
                                        return EMPTY;
                                    }),
                                );
                            }
                            return EMPTY;
                        }),
                    ),
            ),
        ),
    );
}
